import { Expose, Transform, Type } from 'class-transformer'

import { BookBannerType } from './BookBannerType'

export class BookBannersByHighlightGroupIdResponse {
  @Expose()
  @Type(() => BookBannerType)
  @Transform(({ value }) =>
    value.filter((banner: BookBannerType) => banner.isOnWeb)
  )
  data: BookBannerType[]

  @Expose() page: number
}
