import { plainToInstance } from 'class-transformer'

import { GET_NEW_BOOKS } from '@client/collections/Newest/schemas/getNewBooks'
import { gqlApiInstance } from '@client/init'
import { NewestParam } from '@models/newest/NewestParam'
import { NewestResponse } from '@models/newest/NewestResponse'
import { NewestType } from '@models/newest/NewestType'
import { NEW_BOOKS_HOME } from '@client/collections/Newest/schemas/newBooksHome'

export function useNewestAction() {
  async function getNewBooks(params: NewestParam): Promise<NewestResponse> {
    const res = await gqlApiInstance.request(GET_NEW_BOOKS, {
      limitPerPage: params.limitPerPage,
      page: params.page,
      bookTypes: params.bookType,
    })

    return plainToInstance(NewestResponse, res.getNewBooks)
  }

  async function getNewBooksHome(): Promise<NewestType[]> {
    const res = await gqlApiInstance.request(NEW_BOOKS_HOME)

    return plainToInstance(NewestType, res.newBooksHome as [])
  }

  return {
    getNewBooks,
    getNewBooksHome,
  }
}
