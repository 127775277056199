import React from 'react'
import Link from 'next/link'
import cn from 'classnames'
import { SwiperSlide } from 'swiper/react'

import { TopChartSkeleton } from '@components/TopChartSkeleton'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { Swiper } from '@features/home/components/CustomSwiper'
import { RankEighteenIcon } from '@components/icons/RankEighteenIcon'
import { RankEightIcon } from '@components/icons/RankEightIcon'
import { RankElevenIcon } from '@components/icons/RankElevenIcon'
import { RankFifteenIcon } from '@components/icons/RankFifteenIcon'
import { RankFiveIcon } from '@components/icons/RankFiveIcon'
import { RankFourIcon } from '@components/icons/RankFourIcon'
import { RankFourteenIcon } from '@components/icons/RankFourteenIcon'
import { RankNineIcon } from '@components/icons/RankNineIcon'
import { RankNineteenIcon } from '@components/icons/RankNineteenIcon'
import { RankOneIcon } from '@components/icons/RankOneIcon'
import { RankSevenIcon } from '@components/icons/RankSevenIcon'
import { RankSeventeenIcon } from '@components/icons/RankSeventeenIcon'
import { RankSixIcon } from '@components/icons/RankSixIcon'
import { RankSixteenIcon } from '@components/icons/RankSixteenIcon'
import { RankTenIcon } from '@components/icons/RankTenIcon'
import { RankThirteenIcon } from '@components/icons/RankThirteenIcon'
import { RankThreeIcon } from '@components/icons/RankThreeIcon'
import { RankTwelveIcon } from '@components/icons/RankTwelveIcon'
import { RankTwentyIcon } from '@components/icons/RankTwentyIcon'
import { RankTwoIcon } from '@components/icons/RankTwoIcon'
import { TopChartSectionProps } from './interface'
import { TopChartCard } from '../TopChartCard'
import { OptionSelect } from './OptionSelect/OptionSelect'

const chartNumbers = [
  <RankOneIcon className='absolute' width='100' height='152' />,
  <RankTwoIcon className='absolute' width='100' height='152' />,
  <RankThreeIcon className='absolute' width='100' height='152' />,
  <RankFourIcon className='absolute' width='100' height='152' />,
  <RankFiveIcon className='absolute' width='100' height='152' />,
  <RankSixIcon className='absolute' width='100' height='152' />,
  <RankSevenIcon className='absolute' width='100' height='152' />,
  <RankEightIcon className='absolute' width='100' height='152' />,
  <RankNineIcon className='absolute' width='100' height='152' />,
  <RankTenIcon className='absolute' width='100' height='152' />,
  <RankElevenIcon className='absolute' width='100' height='152' />,
  <RankTwelveIcon className='absolute' width='100' height='152' />,
  <RankThirteenIcon className='absolute' width='100' height='152' />,
  <RankFourteenIcon className='absolute' width='100' height='152' />,
  <RankFifteenIcon className='absolute' width='100' height='152' />,
  <RankSixteenIcon className='absolute' width='100' height='152' />,
  <RankSeventeenIcon className='absolute' width='100' height='152' />,
  <RankEighteenIcon className='absolute' width='100' height='152' />,
  <RankNineteenIcon className='absolute' width='100' height='152' />,
  <RankTwentyIcon className='absolute' width='100' height='152' />,
]

export const TopChartSection = React.memo(
  ({
    isLoading,
    data,
    months,
    monthSelected,
    className,
    classNameTitle,
    selectClassName,
    title,
    canSupport,
    canViewAll,
    titleIcon,
    isDesktop,
    handleMonthChange,
    handleSupportCharacter,
    handleGoToStoryDetail,
  }: TopChartSectionProps) => {
    return (
      <div className={cn('text-white', className)}>
        <div className='max-w-[1166px] mx-auto'>
          <div
            className={cn(
              'flex justify-between mb-[20px] mobile:flex-col tablet-mobile:mb-[40px]',
              classNameTitle
            )}
          >
            <h1 className='flex items-center font-mitr text-[24px] font-medium tablet-mobile:mb-[10px] z-10'>
              {titleIcon}
              {title}
            </h1>
            <OptionSelect
              selectClassName={cn(
                'bg-transparent outline-none h-auto border-none !pr-0',
                selectClassName
              )}
              valueClassName='font-[500]'
              iconClassName='text-white'
              value={monthSelected}
              handleSelect={handleMonthChange}
              options={months || []}
              isHomePage
            />
          </div>
          {isLoading ? (
            <div className='flex overflow-hidden tablet-mobile:pl-[20px] tablet-mobile:space-x-[30px]'>
              {[...Array(3)].map((_, index) => (
                <TopChartSkeleton key={index} />
              ))}
            </div>
          ) : data ? (
            <Swiper
              slidesPerView={
                isDesktop && data.length >= 4
                  ? 'auto'
                  : !isDesktop && data.length >= 3
                  ? 'auto'
                  : data.length
              }
              spaceBetween={30}
              loop
              isShowNavigation
              prevclass='left-[-45px] tablet:left-[5px] mobile:hidden'
              nextclass='right-[-45px] tablet:right-[5px] mobile:hidden'
              speed={1000}
            >
              {data.map((row, index) => (
                <SwiperSlide key={row.bookCharacter.id}>
                  <TopChartCard
                    id={row.bookCharacter.id}
                    bookId={row.bookCharacter.book.id}
                    className='tablet-mobile:first:ml-[20px]'
                    storyName={row.bookCharacter.book.title}
                    characterName={row.bookCharacter.name}
                    characterType={row.bookCharacter.characterRole.name}
                    characterContent={row.bookCharacter.description}
                    imageUrl={
                      row.bookCharacter.resizeImgPath ||
                      row.bookCharacter.imgPath
                    }
                    characterRank={row.characterRank}
                    characterNextRank={row.characterNextRank}
                    remainingCoin={row.remainingCoin}
                    cumulativeGainedCoin={row.cumulativeGainedCoin}
                    icon={chartNumbers[index]}
                    characterRankStartCoins={
                      row.bookCharacter.characterRank.startCoins
                    }
                    canSupport={
                      canSupport &&
                      row.bookCharacter.donatable &&
                      row.bookCharacter.book.status ===
                        BookStatusEnum.PUBLISHED &&
                      !row.bookCharacter.book.deletedAt &&
                      row.bookCharacter.visible &&
                      !row.bookCharacter.deletedAt
                    }
                    onSupport={() => handleSupportCharacter(row)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}
          {canViewAll && (
            <div className='text-center mt-[30px]'>
              <Link href='/character/top' legacyBehavior prefetch={false}>
                <a className='font-mitr underline text-[14px] font-medium text-white'>
                  ดูตัวละครทั้งหมด
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    )
  }
)
