import { Expose, Type } from 'class-transformer'
import { NewestType } from './NewestType'

export class NewestResponse {
  @Expose()
  @Type(() => NewestType)
  data: NewestType[]

  @Expose()
  total: number

  @Expose()
  page: number
}
