import React from 'react'
import type { GetServerSideProps } from 'next'

import { HomePage } from '@features/home/pages/HomePage'
import { AppLayout } from '@components/layouts/AppLayout'
import { HomeProps } from '@features/home/pages/HomePage/interface'
import { BASE_URL } from '@configs/config'
import { OgShare } from '@components/OgShare'
import { isMaintenanceHandler } from '@lib/maintenanceHandler'

function Home(props: HomeProps) {
  return (
    <AppLayout>
      <OgShare url={BASE_URL} />
      <HomePage {...props} />
    </AppLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  const serverDate = new Date()

  try {
    const isMaintenance = await isMaintenanceHandler()
    if (isMaintenance) {
      return {
        redirect: {
          destination: '/maintenance',
          permanent: false,
        },
        props: {
          isMaintenance: !!isMaintenance,
        },
      }
    }
    return {
      props: {
        serverDate: JSON.parse(JSON.stringify(serverDate)),
        isMaintenance: !!isMaintenance,
      },
    }
  } catch (error) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
      props: {
        serverDate: JSON.parse(JSON.stringify(serverDate)),
        isMaintenance: false,
      },
    }
  }
}

export default Home
