import { gql } from 'graphql-request'

export const GET_NEW_BOOKS = gql`
  query GetNewBooks($limitPerPage: Int, $page: Int, $bookTypes: [BookType!]!) {
    getNewBooks(
      limitPerPage: $limitPerPage
      page: $page
      bookTypes: $bookTypes
    ) {
      data {
        id
        writer
        coverImgPath
        coverResizeImgPath
        cover
        title
        publishedChapterCount
        viewCount
        isLiked
        likeCount
        commentCount
        isEnded
        category {
          id
          name
        }
        penName {
          firstPenName
          user {
            id
            displayName
            profilePageSlug
            avatarImgPath
            writer {
              affiliation
              status
            }
          }
        }
        tags {
          id
          name
        }
        bookType
        writingType
        publishedAt
        isTranslated
        isEpub
        isPdf
        rating {
          id
          name
        }
      }
      total
      page
    }
  }
`
