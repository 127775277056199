import { Expose } from 'class-transformer'

export class MainBannerType {
  @Expose() id: number

  @Expose() web: boolean

  @Expose() coverImgPath: string

  @Expose({ name: 'linkWeb' }) link: string

  @Expose() description: string
}
