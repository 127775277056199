import { Expose, Type } from 'class-transformer'
import { LatestUpdateType } from './LatestUpdateType'

export class LatestUpdateResponse {
  @Expose()
  @Type(() => LatestUpdateType)
  data: LatestUpdateType[]

  @Expose()
  total: number

  @Expose()
  page: number
}
