import { Expose, Type, Transform } from 'class-transformer'
import { BannerEnum } from '@interfaces/BannerEnum'
import { BookBannerType } from './BookBannerType'
import { PosterBannerType } from './PosterBannerType'

export class HighlightBannerType {
  @Expose()
  id: number

  @Expose()
  bannerName: string

  @Expose()
  bannerType: BannerEnum

  @Expose({ name: 'paginatedPosterBanner' })
  @Type(() => PosterBannerType)
  @Transform(({ obj }) =>
    obj.bannerType === BannerEnum.POSTER
      ? obj.paginatedPosterBanner.filter(
          (banner: PosterBannerType) => banner.isOnWeb
        )
      : null
  )
  posterBanner: PosterBannerType[]

  @Expose({ name: 'paginatedBookBanner' })
  @Type(() => BookBannerType)
  @Transform(({ obj }) =>
    obj.bannerType === BannerEnum.BOOK
      ? obj.paginatedBookBanner.filter(
          (banner: BookBannerType) => banner.isOnWeb
        )
      : null
  )
  bookBanner: BookBannerType[]
}
