import cn from 'classnames'

import { BannerEnum } from '@interfaces/BannerEnum'
import { HighlightBannerType } from '@models/banner/HighlightBannerType'
import { HighlightPosterBanner } from './components/HighlightPosterBanner'
import { HighlightBookBanner } from './components/HighlightBookBanner'
import { HighlightBannerProps } from './interface'

function BannerComponent({
  highlightBanner,
}: {
  highlightBanner: HighlightBannerType
}) {
  if (BannerEnum.POSTER === highlightBanner.bannerType) {
    return (
      <HighlightPosterBanner
        key={highlightBanner.id}
        id={highlightBanner.id}
        bannerName={highlightBanner.bannerName}
        banners={highlightBanner.posterBanner}
      />
    )
  }

  return (
    <HighlightBookBanner
      key={highlightBanner.id}
      id={highlightBanner.id}
      bannerName={highlightBanner.bannerName}
      banners={highlightBanner.bookBanner}
    />
  )
}

export function HighlightBanner({
  highlightBanners,
  className,
}: HighlightBannerProps) {
  return (
    <section
      className={cn(
        className,
        'w-full grid grid-cols-1 gap-y-[60px] tablet-mobile:gap-y-[40px]'
      )}
    >
      {highlightBanners
        .filter(
          highlightBanner =>
            !!highlightBanner.bookBanner?.length ||
            !!highlightBanner.posterBanner?.length
        )
        .map(row => (
          <BannerComponent key={row.id} highlightBanner={row} />
        ))}
    </section>
  )
}
