import React from 'react'

import { TopChartCardProps } from './interface'

export function withTopChartCard(Component: React.FC<TopChartCardProps>) {
  function WithTopChartCard(props: TopChartCardProps) {
    return <Component {...props} />
  }
  return WithTopChartCard
}
