import { Expose, Transform, Type } from 'class-transformer'

import { PosterBannerType } from './PosterBannerType'

export class PosterBannersByHighlightGroupIdResponse {
  @Expose()
  @Type(() => PosterBannerType)
  @Transform(({ value }) =>
    value.filter((banner: PosterBannerType) => banner.isOnWeb)
  )
  data: PosterBannerType[]

  @Expose() page: number
}
