import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { useDrawer } from '@hooks/contexts/DrawerContext/DrawerContext'
import { useAuthentication } from '@hooks/useAuthentication'
import { useBannerAction } from '@hooks/banner/useBannerAction'
import { HomePageProps, HomeProps } from './interface'

export function withHomePage(Component: React.FC<HomePageProps>) {
  function WithHomePage(props: HomeProps) {
    const { query } = useRouter()
    const bannerClient = useBannerAction()
    const { canFetchApi, isAuthenticated } = useAuthentication()
    const chatDrawer = useDrawer({
      drawer: 'chatDrawer',
      drawerProps: {
        chatDocId: query.id,
      },
    })

    const { data, isLoading } = useQuery('highlight-banner', () =>
      bannerClient.getHighlightBanners()
    )

    useEffect(() => {
      if (query.type === 'chat' && query.id && canFetchApi && isAuthenticated) {
        chatDrawer.show()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, canFetchApi, isAuthenticated])

    const newProps = {
      ...props,
      highlightBannersTop: data?.highlightBannersTop,
      highlightBannersBottom: data?.highlightBannersBottom,
      isLoading,
    }

    return <Component {...newProps} />
  }
  return WithHomePage
}
