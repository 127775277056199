import cn from 'classnames'
import React from 'react'
import Link from 'next/link'

import { Tooltip } from '@components/TooltipCustom'
import CoinButton from '@components/CoinButton'
import { Avatar } from '@components/Avatar'
import { ProgressBar } from '@components/ProgressBar'
import { SocialSharing } from '@components/SocialSharing'
import { BASE_URL } from '@configs/config'
import { ArrowRight2Icon } from '@components/icons/ArrowRight2Icon'
import { CharacterInfoIcon } from '@components/icons/CharacterInfoIcon'
import { CharacterInformationProps, TopChartCardProps } from './interface'

export function CharacterInformation({ content }: CharacterInformationProps) {
  return (
    <div className='font-light text-[12px] text-blue-1 max-w-[288px]'>
      <div className='text-[10px] text-secondary'>รายละเอียด</div>
      <div className='break-word'>{content || '-'}</div>
    </div>
  )
}

export function TopChartCard({
  id,
  bookId,
  className,
  storyName,
  characterName,
  characterType,
  characterContent,
  imageUrl,
  characterRank,
  characterNextRank,
  remainingCoin,
  cumulativeGainedCoin,
  icon,
  canSupport,
  characterRankStartCoins,
  onSupport,
}: TopChartCardProps) {
  return (
    <div
      className={cn(
        'relative w-[300px] h-[472px] mobile:w-[256px] mobile:h-[382px]',
        className
      )}
    >
      <div className='relative top-0 right-0 z-[1] w-[300px] mobile:w-[256px]'>
        <span className='absolute left-0'>{icon}</span>
        <Avatar
          className='z-[1] w-[220px] ml-auto mobile:w-[164px]'
          src={imageUrl}
          alt={characterName}
        />
      </div>
      <div className='absolute bottom-0 left-0 z-0 flex items-end bg-[url("/top-chart.webp")] bg-contain bg-no-repeat bg-bottom w-[300px] h-[390px] mobile:w-[256px] mobile:h-[327px]'>
        <div className='w-full px-[20px] pt-[10px] pb-[20px] desktop-tablet:h-[244px] mobile:h-[230px]'>
          <Tooltip label={<CharacterInformation content={characterContent} />}>
            <CharacterInfoIcon width='24' height='24' />
          </Tooltip>

          <Link href={`/story/${bookId}`} prefetch={false} legacyBehavior>
            <a>
              <div className='text-gray-4 font-[300] my-[5px] mobile:text-[12px] truncate'>
                {storyName}
              </div>
            </a>
          </Link>
          <div className='flex justify-between items-center mb-[12px] tablet:mb-[5px] mobile:mb-[10px]'>
            <div className='font-mitr text-[18px] font-medium line-clamp-1 break-all'>
              {characterName}
            </div>
            <div className='text-gray-4 whitespace-nowrap tablet:text-white font-[300] tablet:text-[14px] mobile:text-[12px]'>
              {characterType}
            </div>
          </div>

          {characterNextRank ? (
            <div className='flex justify-between items-center text-[14px] font-light mb-[10px] mobile:text-[12px] mobile:text-gray-4'>
              <div>อีก {remainingCoin.toLocaleString()} เหรียญ</div>
              <ArrowRight2Icon />
              <span className='text-[16px] font-bold text-yellow'>
                {characterNextRank}
              </span>
            </div>
          ) : (
            <p className='text-[14px] font-light text-center'>
              Rank:{' '}
              <span className='text-[16px] font-bold text-yellow'>
                {characterRank}
              </span>
            </p>
          )}
          <ProgressBar
            className='my-[10px]'
            progressBarClassName='bg-primary'
            value={cumulativeGainedCoin - characterRankStartCoins}
            max={remainingCoin + cumulativeGainedCoin - characterRankStartCoins}
          />
          <div className='text-[14px] font-light mb-[15px] mobile:text-[12px]'>
            ได้รับ {cumulativeGainedCoin.toLocaleString()} เหรียญ
          </div>
          <div
            className='flex items-center justify-between'
            onClick={e => e.stopPropagation()}
          >
            <CoinButton
              type='button'
              disabled={!canSupport}
              className='w-full'
              secondClassName='mobile:text-sm h-[30px]'
              onClick={event => {
                event.stopPropagation()
                if (onSupport) {
                  onSupport()
                }
              }}
              size={30}
              data-test='donate-top-chart-character'
            >
              สนับสนุน
            </CoinButton>
            <SocialSharing
              buttonClassName='!text-white border-none'
              shareUrl={`${BASE_URL}/share?characterId=${id}&bookId=${bookId}`}
              bookId={bookId}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
