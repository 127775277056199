import { gql } from 'graphql-request'

export const GET_POSTER_BANNERS_BY_HIGHLGHT_GROUP_ID = gql`
  query ActivePosterBannersByHighlightGroupId(
    $page: Int
    $highlightGroupId: Int!
  ) {
    activePosterBannersByHighlightGroupId(
      limitPerPage: 10
      page: $page
      highlightGroupId: $highlightGroupId
    ) {
      data {
        id
        runningNo
        imgPath
        description
        linkWeb
        isOnWeb
      }
      page
    }
  }
`
