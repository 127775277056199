import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { useWindowSize } from '@hooks/useWindowSize'
import { useCharacterAction } from '@hooks/character/useCharacterAction'
import { DailyTopChartCharacterProps } from './interface'

const withDailyTopChartCharacter = (
  Component: React.FC<DailyTopChartCharacterProps>
) => {
  function WithDailyTopChartCharacter() {
    const [currentDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)))
    const characterClient = useCharacterAction()
    const { isDesktop, isMobile, isTablet } = useWindowSize()

    const { data, isLoading } = useQuery(
      ['dailyTopChartCharacter', currentDate],
      () => characterClient.dailyTopChartCharacter(currentDate),
      {
        enabled: !!currentDate,
      }
    )

    const newProps = {
      data,
      isLoading,
      isDesktop,
      isMobile,
      isTablet,
    }

    return <Component {...newProps} />
  }

  return WithDailyTopChartCharacter
}

export default withDailyTopChartCharacter
