export function TopChartSkeleton() {
  return (
    <div className='animate-pulse bg-[url("/top-chart.webp")] bg-contain bg-no-repeat bg-bottom min-w-[300px] min-h-[472px]  mobile:min-w-[256px] mobile:min-h-[382px] desktop:mx-auto'>
      <div className='flex flex-col space-y-2'>
        <div className='rounded-full bg-gray h-[220px] w-[220px] mobile:h-[164px] mobile:w-[164px] ml-auto' />
        <div className='flex-1 space-y-4 p-2'>
          <div className='rounded-full bg-gray h-[24px] w-[24px]' />
          <div className='w-[70%] h-3 bg-gray rounded-xl' />
          <div className='h-2 bg-gray rounded' />
          <div className='h-2 bg-gray rounded' />
          <div className='h-2 bg-gray rounded' />
          <div className='h-2 bg-gray rounded' />
          <div className='flex items-center space-x-5'>
            <div className='w-[60%] h-4 bg-gray rounded-xl' />
            <div className='w-[40%] h-8 bg-gray rounded-3xl' />
          </div>
        </div>
      </div>
    </div>
  )
}
