import { Expose, Type } from 'class-transformer'

import { HighlightBannerType } from './HighlightBannerType'

export class HighlightBannerResponse {
  @Expose()
  @Type(() => HighlightBannerType)
  highlightBannersTop: HighlightBannerType[]

  @Expose()
  @Type(() => HighlightBannerType)
  highlightBannersBottom: HighlightBannerType[]
}
