import cn from 'classnames'
import { SelectButton } from '@components/SelectButton'

import { CategoryMenuProps } from './interface'

export function CategoryMenu({
  className,
  selectedCategory,
  menuOptions,
  handleCategoryMenuChange,
}: CategoryMenuProps) {
  return (
    <section
      className={cn(
        'overflow-x-auto scrollbar-none scrollbar-none::-webkit-scrollbar',
        className
      )}
    >
      <SelectButton
        options={menuOptions}
        value={selectedCategory}
        onChange={handleCategoryMenuChange}
      />
    </section>
  )
}
