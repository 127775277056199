import { gql } from 'graphql-request'

export const GET_MAIN_BANNER = gql`
  query GetMainBannersPublished($platform: PlatformEnum!) {
    mainBannersPublished(platform: $platform) {
      id
      web
      coverImgPath
      linkWeb
      description
    }
  }
`
