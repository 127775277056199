import React, { useState } from 'react'

import { useEbookAction } from '@hooks/ebook/useEbookAction'
import { useMutation, useQuery } from 'react-query'
import { SearchCategoryEbookResponse } from '@models/ebook/SearchCategoryEbookResponse'
import { WishlisEnum } from '@models/ebook/WishlishEnum'
import cloneDeep from 'lodash.clonedeep'
import { EbookLogEnum } from '@interfaces/EbookLogEnum'
import { useWishlistAction } from '@hooks/ebook/useWishlistAction'
import { queryClient } from '@client/init'
import { useAuthentication } from '@hooks/useAuthentication'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { EbookNewProps, EbookNewAcceptProps, FormatType } from './interface'

export function withEbookNew(Component: React.FC<EbookNewProps>) {
  function WithEbookNew({ ...props }: EbookNewAcceptProps) {
    const [format] = useState<FormatType>({
      grid: [0, 1, 2, 3],
      col: 4,
    })
    const { toggleWishlist } = useWishlistAction()
    const ebookClient = useEbookAction()
    const { isAuthenticated, canFetchApi } = useAuthentication()
    const loginModal = useModal({ modal: 'login' })

    const { data: datas, isLoading } = useQuery(
      ['ebook-new-home'],
      () => ebookClient.getEbookNewestList({ limitPerPage: 20, page: 1 }),
      {
        enabled: canFetchApi,
      }
    )

    const { mutateAsync: toggleWishlistEbook, isLoading: isToggleWishlist } =
      useMutation(
        (id: number) => toggleWishlist(id, EbookLogEnum.EBOOK_WISHLIST),
        {
          onSuccess: async (type, id) => {
            queryClient.setQueryData<SearchCategoryEbookResponse | undefined>(
              ['ebook-new-home'],
              (oldData?: SearchCategoryEbookResponse) => {
                if (oldData) {
                  const temp = cloneDeep(oldData)
                  const index = temp.data?.findIndex(row => row.id === id)
                  if (index !== -1) {
                    temp.data[index].isWishlist = type === WishlisEnum.CREATE
                    temp.data[index].wishlistCount =
                      type === WishlisEnum.CREATE
                        ? temp.data[index].wishlistCount + 1
                        : temp.data[index].wishlistCount - 1
                    return temp
                  }

                  return oldData
                }

                return oldData
              }
            )
          },
        }
      )

    async function handleWishlist(id: number) {
      if (isAuthenticated) {
        await toggleWishlistEbook(id)
      } else {
        loginModal.show()
      }
    }

    const componentProps = {
      ...props,
      datas: datas?.data || [],
      format,
      is1Row: datas ? datas.data.length <= 2 : false,
      isLoading,
      isToggleWishlist,
      handleWishlist,
    }

    return <Component {...componentProps} />
  }

  return WithEbookNew
}
