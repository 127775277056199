import cn from 'classnames'

import { ArrowUpIcon } from '@components/icons/ArrowUpIcon'
import { GoToTopButtonProps } from './interface'

export function GoToTopButton({
  isShowButton,
  className,
  handleScrollUp,
}: GoToTopButtonProps) {
  return (
    <div
      className={cn(
        'fixed z-10 bottom-[5%] right-[20px] w-[40px] h-[40px] flex items-center justify-center bg-primary rounded-full cursor-pointer',
        { hidden: !isShowButton },
        className
      )}
    >
      <button onClick={handleScrollUp}>
        <ArrowUpIcon className='text-white' width='24px' height='24px' />
      </button>
    </div>
  )
}
