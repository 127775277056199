import { Expose } from 'class-transformer'

export class PosterBannerType {
  @Expose()
  id: number

  @Expose()
  runningNo: number

  @Expose()
  imgPath: string

  @Expose()
  linkWeb: string

  @Expose()
  isOnWeb: boolean

  @Expose()
  description: string
}
