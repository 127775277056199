import { gql } from 'graphql-request'

export const GET_BOOK_BANNERS_BY_HIGHLGHT_GROUP_ID = gql`
  query ActiveBookBannersByHighlightGroupId(
    $page: Int
    $highlightGroupId: Int!
  ) {
    activeBookBannersByHighlightGroupId(
      limitPerPage: 10
      page: $page
      highlightGroupId: $highlightGroupId
    ) {
      data {
        id
        bookId
        runningNo
        isOnWeb
        book {
          id
          title
          writer
          coverImgPath
          coverResizeImgPath
          writingType
          isTranslated
          penName {
            firstPenName
          }
        }
      }
      page
    }
  }
`
