import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'

import { formatDateDomini } from '@lib/utils'
import { useBookTopCharts } from '@hooks/book/useBookTopChart'
import { useDrawer } from '@hooks/contexts/DrawerContext/DrawerContext'
import { useAlert } from '@hooks/useAlert'
import { useWindowSize } from '@hooks/useWindowSize'
import { BookTopChartsType } from '@models/story/BookTopChartsType'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { WithBookTopChartProps, BookTopChartProps } from './interface'

export function withBookTopChart(Component: React.FC<BookTopChartProps>) {
  function WithBookTopChart({ title, ...props }: WithBookTopChartProps) {
    const dt = DateTime.local()
    const {
      data,
      query,
      isLoading,
      lastWeek,
      handleCategoryChange,
      handleBookTypeChange,
      nextWeek,
      prevWeek,
    } = useBookTopCharts()

    const disablePrev = useMemo(
      () =>
        query.startDate.toFormat('yyyy-MM-dd') ===
        dt.startOf('year').endOf('week').toFormat('yyyy-MM-dd'),
      [query.startDate, dt]
    )

    const disableNext = useMemo(
      () =>
        query.endDate.toFormat('yyyy-MM-dd') ===
        lastWeek.endOf('week').toFormat('yyyy-MM-dd'),
      [query.endDate, lastWeek]
    )

    const dateOfWeek = `${formatDateDomini(
      query.startDate.toString()
    )} - ${formatDateDomini(query.endDate.toString())}`

    const storyDrawer = useDrawer({ drawer: 'story' })
    const alert = useAlert()
    const { isDesktop, isMobile } = useWindowSize()

    const handleClickTopChart = useCallback((value: BookTopChartsType) => {
      if (value.book.isBanned) return

      if (
        value.book.status !== BookStatusEnum.PUBLISHED ||
        value.book.deletedAt
      ) {
        alert.warning('หนังสือเรื่องนี้ไม่เผยแพร่/ถูกลบแล้ว')
        return
      }

      storyDrawer.show({ bookId: value.book.id })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const componentProps = {
      ...props,
      title,
      query,
      books: data?.pages[0]?.data,
      isLoading,
      dateOfWeek,
      disablePrev,
      disableNext,
      isDesktop,
      isMobile,
      handleCategoryChange,
      handleBookTypeChange,
      nextWeek,
      prevWeek,
      handleClickTopChart,
    }

    return <Component {...componentProps} />
  }

  return WithBookTopChart
}
