import { plainToInstance } from 'class-transformer'

import { gqlApiInstance } from '@client/init'
import { PlatformEnum } from '@interfaces/PlatformEnum'
import { MainBannerType } from '@models/banner/MainBannerType'
import { HighlightBannerResponse } from '@models/banner/HighlightBannerResponse'
import { GET_MAIN_BANNER } from '@client/collections/Banner/schemas/getMainBanner'
import { GET_ALL_ACTIVE_HIGHLIGHT_BANNER } from '@client/collections/Banner/schemas/getAllActiveHighlightBanner'
import { GET_BOOK_BANNERS_BY_HIGHLGHT_GROUP_ID } from '@client/collections/Banner/schemas/getBookBannersByHighlightGroupId'
import { GET_POSTER_BANNERS_BY_HIGHLGHT_GROUP_ID } from '@client/collections/Banner/schemas/getPosterBannersByHighlightGroupId'
import { BookBannersByHighlightGroupIdResponse } from '@models/banner/BookBannersByHighlightGroupIdResponse'
import { PosterBannersByHighlightGroupIdResponse } from '@models/banner/PosterBannersByHighlightGroupIdResponse'

export function useBannerAction() {
  async function getMainBanners(): Promise<MainBannerType[]> {
    const { mainBannersPublished } = await gqlApiInstance.request(
      GET_MAIN_BANNER,
      {
        platform: PlatformEnum.WEB,
      }
    )

    return plainToInstance(MainBannerType, mainBannersPublished as [])
  }

  async function getHighlightBanners(): Promise<HighlightBannerResponse> {
    const response = await gqlApiInstance.request(
      GET_ALL_ACTIVE_HIGHLIGHT_BANNER
    )

    return plainToInstance(HighlightBannerResponse, response)
  }

  async function getBookBannersByHighlightGroupId({
    highlightGroupId,
    page,
  }: {
    highlightGroupId: number
    page: number
  }): Promise<BookBannersByHighlightGroupIdResponse> {
    const response = await gqlApiInstance.request(
      GET_BOOK_BANNERS_BY_HIGHLGHT_GROUP_ID,
      {
        highlightGroupId,
        page,
      }
    )

    return plainToInstance(
      BookBannersByHighlightGroupIdResponse,
      response.activeBookBannersByHighlightGroupId
    )
  }

  async function getPosterBannersByHighlightGroupId({
    highlightGroupId,
    page,
  }: {
    highlightGroupId: number
    page: number
  }): Promise<PosterBannersByHighlightGroupIdResponse> {
    const response = await gqlApiInstance.request(
      GET_POSTER_BANNERS_BY_HIGHLGHT_GROUP_ID,
      {
        highlightGroupId,
        page,
      }
    )

    return plainToInstance(
      PosterBannersByHighlightGroupIdResponse,
      response.activePosterBannersByHighlightGroupId
    )
  }

  return {
    getMainBanners,
    getHighlightBanners,
    getBookBannersByHighlightGroupId,
    getPosterBannersByHighlightGroupId,
  }
}
