import cn from 'classnames'

interface ProgressBarProps {
  value: number
  max: number
  className?: string
  progressBarClassName?: string
}

export function ProgressBar({
  value,
  max,
  className,
  progressBarClassName,
}: ProgressBarProps) {
  const progress = (value / max) * 100
  return (
    <div
      className={cn(
        'w-full bg-gray-4 h-[4px] rounded-[40px] overflow-hidden',
        className
      )}
    >
      <div
        className={cn('h-[4px]', progressBarClassName)}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  )
}
