import React, { useCallback, useEffect, useState } from 'react'
import { plainToInstance } from 'class-transformer'
import { useQuery } from 'react-query'

import { useDrawer } from '@hooks/contexts/DrawerContext/DrawerContext'
import { useAuthentication } from '@hooks/useAuthentication'
import { LatestUpdateType } from '@models/latestUpdate/LatestUpdateType'
import { StoryDetailReaderType } from '@models/story/StoryDetailReaderType'
import { useLatestUpdateAction } from '@hooks/latestUpdate/useLatestUpdateAction'
import {
  FormatType,
  WithLatestUpdateBookProps,
  LatestUpdateBookProps,
} from './interface'

export function withLatestUpdateBook(
  Component: React.FC<LatestUpdateBookProps>
) {
  function WithLatestUpdateBook({
    categoryId,
    bookId,
    isEbook,
    bookType,
    isStoryPage = false,
    ...props
  }: WithLatestUpdateBookProps) {
    const latestUpdateClient = useLatestUpdateAction()
    const [format, setFormat] = useState<FormatType>({
      grid: [0, 1, 2, 3],
      col: 4,
    })

    const { data, isLoading } = useQuery('latest-updated-books-home', () =>
      latestUpdateClient.getLatestUpdatedBooksHome()
    )
    const storyDrawer = useDrawer({ drawer: 'story' })

    const showStoryDrawer = useCallback(
      (value: LatestUpdateType) => {
        storyDrawer.show({
          bookId: value.id,
          initialData: plainToInstance(StoryDetailReaderType, {
            id: value.id,
            coverImgPath: value.coverImgPath,
            title: value.title,
            writingType: value.writingType,
            publishedAt: value.publishedAt,
            cover: value.cover,
            viewCount: value.viewCount,
            commentCount: value.commentCount,
            likeCount: value.likeCount,
            publishedChapterCount: value.chapterCount,
            penName: value.penName,
            category: value.category,
            bookType: value.bookType,
            tags: value.tags,
            rating: value.rating,
            isTranslated: value.isTranslated,
            isEnded: value.isEnded,
            isEpub: value.isEpub,
            isPdf: value.isPdf,
            isLiked: value.isLiked,
          }),
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    useEffect(() => {
      if (isStoryPage) {
        const col = /Android|iPhone/i.test(navigator.userAgent) ? 2 : 4
        setFormat({
          grid: Array.from({ length: col }).map((_, index) => index),
          col,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const newProps = {
      ...props,
      datas: data?.length ? data : [],
      isLoading,
      format,
      categoryId,
      bookType,
      isEbook,
      is1Row: data?.length ? data.length <= 2 : false,
      showStoryDrawer,
    }
    return <Component {...newProps} />
  }

  return WithLatestUpdateBook
}
