import cn from 'classnames'
import Link from 'next/link'
import { SwiperSlide } from 'swiper/react'

import { Swiper } from '@features/home/components/CustomSwiper'
import { EbookCard } from '@components/EbookCard'
import { EBookStarIcon } from '@components/icons/EBookStarIcon'
import { Right2Icon } from '@components/icons/Right2Icon'
import StoryCardSkeleton from '@components/StoryCardSkeleton'
import { EbookNewProps } from './interface'

export function EbookNew({
  datas,
  is1Row,
  format,
  direction,
  isLoading,
  isShowNavigation,
  isToggleWishlist,
  className,
  handleWishlist,
}: EbookNewProps) {
  if (!isLoading && datas.length === 0) return null

  return (
    <section className={className}>
      <div
        className={cn('w-full flex flex-col', {
          'min-h-[572px] mobile:min-h-[868px] tablet:min-h-[505px]': isLoading,
        })}
      >
        <div className='font-mitr flex justify-between items-center mobile:mx-[21px] mb-[20px] mobile:mb-[10px]'>
          <div className='flex'>
            <EBookStarIcon className='text-primary w-[38px] h-[38px] mr-[10px] mobile:w-[24px] mobile:h-[24px]' />
            <h2 className='topic-base mobile:topic-small'>อีบุ๊กมาใหม่</h2>
          </div>
          <Link
            href={{ pathname: '/ebook/newest' }}
            legacyBehavior
            prefetch={false}
          >
            <a className='flex items-center text-[14px] text-secondary cursor-pointer font-medium'>
              ดูทั้งหมด&nbsp;
              <Right2Icon width='14' height='14' />
            </a>
          </Link>
        </div>

        {isLoading ? (
          <div
            className={cn('grid gap-[20px] mx-auto mobile:max-w-[450px]', {
              'grid-cols-2 grid-rows-2 tablet:grid-cols-4 tablet:grid-rows-1':
                direction === 'horizontal',
              'grid-cols-4 grid-rows-1 mobile:grid-cols-2 tablet:grid-cols-4 tablet:grid-rows-1':
                direction === 'vertical',
            })}
          >
            {[...Array(is1Row ? 2 : 4)].map((l, i) => (
              <StoryCardSkeleton key={i} direction={direction} />
            ))}
          </div>
        ) : (
          <Swiper
            slidesPerView={1}
            loop
            isShowNavigation={isShowNavigation}
            centeredSlides
            className='recommended-swiper'
            containerClass='pb-[28px]'
            prevclass={cn('left-[-45px] tablet-mobile:hidden', {
              'top-[46%]': direction === 'horizontal' && !is1Row,
              'top-[42%]': direction === 'horizontal' && is1Row,
              'top-[46.6%]': direction === 'vertical',
            })}
            nextclass={cn('right-[-45px] tablet-mobile:hidden', {
              'top-[46%]': direction === 'horizontal' && !is1Row,
              'top-[42%]': direction === 'horizontal' && is1Row,
              'top-[46.6%]': direction === 'vertical',
            })}
            count={4}
            spaceBetween={20}
            speed={1000}
          >
            {Array.from(
              { length: Math.ceil(datas.length / format.col) },
              (v, i) => i
            ).map((item: number) => {
              return (
                <SwiperSlide key={item}>
                  <div
                    className={cn(
                      'mobile:max-w-[450px] w-full mobile:mx-[20px] grid gap-[20px] mb-[20px] mobile:gap-[10px] grid-cols-2 grid-rows-2 tablet:grid-cols-4 tablet:grid-rows-1',
                      {
                        'grid-cols-2 grid-rows-2 tablet:grid-cols-4 tablet:grid-rows-1':
                          direction === 'horizontal' && !is1Row,
                        'grid-cols-2 grid-rows-1 tablet:grid-cols-2 tablet:grid-rows-1':
                          direction === 'horizontal' && is1Row,
                        'grid-cols-4 grid-rows-1 mobile:grid-cols-2 tablet:grid-cols-4 tablet:grid-rows-1':
                          direction === 'vertical',
                      }
                    )}
                  >
                    {format.grid.map((col: number) => {
                      const index = item * format.col + col
                      if (index < datas.length) {
                        const row = datas[index]
                        return (
                          <EbookCard
                            key={row.id}
                            id={row.id}
                            imageUrl={row.coverImgPath}
                            title={row.title}
                            penName={row.penName.firstPenName}
                            slug={row.penName.user.profilePageSlug}
                            content={row.cover}
                            category={row.category.name}
                            tags={row.tags}
                            wishlistCount={row.wishlistCount}
                            review={row.reviewCount}
                            bookType={row.bookType}
                            categoryId={row.category.id}
                            price={row.price}
                            avgRating={row.avgRating}
                            promotion={row.promotion}
                            isWishlist={row.isWishlist}
                            isToggleWishlist={isToggleWishlist}
                            handleWishlist={handleWishlist}
                          />
                        )
                      }
                      return null
                    })}
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
    </section>
  )
}
