export type FunctionComponentType = {
  className?: string
}

export function Skeleton({ className }: FunctionComponentType) {
  return (
    <div className={className}>
      <div className='relative w-full pt-[42.86%]'>
        <div className='animate-pulse bg-gray absolute top-0 left-0 right-0 bottom-0' />
      </div>
    </div>
  )
}

export function MainBannerSkeleton() {
  return (
    <div className='flex justify-center items-center overflow-hidden desktop:my-[20px]'>
      {[...Array(3)].map((_, index) => (
        <Skeleton
          key={index}
          className='desktop:w-[466.7px] tablet-mobile:hidden rounded-lg desktop:mx-3 shrink-0 desktop-tablet:rounded-lg desktop-tablet:overflow-hidden'
        />
      ))}
      <Skeleton className='desktop:w-[747px] tablet-mobile:w-full rounded-lg desktop:mx-3 shrink-0 desktop-tablet:rounded-lg desktop-tablet:overflow-hidden' />
      {[...Array(3)].map((_, index) => (
        <Skeleton
          key={index}
          className='desktop:w-[466.7px] tablet-mobile:hidden rounded-lg desktop:mx-3 shrink-0 desktop-tablet:rounded-lg desktop-tablet:overflow-hidden'
        />
      ))}
    </div>
  )
}

export function HighlightBannerSkeleton() {
  return (
    <>
      <div className='flex justify-center items-center my-10 overflow-hidden'>
        {[...Array(7)].map((_, index) => (
          <Skeleton
            key={index}
            className='w-[335px] rounded-lg mx-3 shrink-0'
          />
        ))}
      </div>

      <div className='flex justify-center items-center my-10 overflow-hidden'>
        {[...Array(15)].map((_, index) => (
          <Skeleton
            key={index}
            className='w-[335px] rounded-lg mx-3 shrink-0'
          />
        ))}
      </div>
    </>
  )
}
