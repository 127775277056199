import { plainToInstance } from 'class-transformer'

import { GET_LASTEST_UPDATED_BOOKS } from '@client/collections/LatestUpdate/schemas/getLatestUpdatedBooks'
import { gqlApiInstance } from '@client/init'
import { LatestUpdateParam } from '@models/latestUpdate/LatestUpdateParam'
import { LatestUpdateResponse } from '@models/latestUpdate/LatestUpdateResponse'
import { BookEnum } from '@interfaces/BookEnum'
import { LatestUpdateType } from '@models/latestUpdate/LatestUpdateType'
import { LASTEST_UPDATED_BOOKS_HOME } from '@client/collections/LatestUpdate/schemas/latestUpdatedBooksHome'

export function useLatestUpdateAction() {
  async function getLatestUpdatedBooks(
    params: LatestUpdateParam
  ): Promise<LatestUpdateResponse> {
    const res = await gqlApiInstance.request(GET_LASTEST_UPDATED_BOOKS, {
      limitPerPage: params.limitPerPage,
      page: params.page,
      bookTypes: params.bookType
        ? [params.bookType]
        : [BookEnum.MANGA, BookEnum.NOVEL],
    })

    return plainToInstance(LatestUpdateResponse, res.getLatestUpdatedBooks)
  }

  async function getLatestUpdatedBooksHome(): Promise<LatestUpdateType[]> {
    const res = await gqlApiInstance.request(LASTEST_UPDATED_BOOKS_HOME)

    return plainToInstance(LatestUpdateType, res.latestUpdatedBooksHome as [])
  }

  return {
    getLatestUpdatedBooks,
    getLatestUpdatedBooksHome,
  }
}
