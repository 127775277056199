import { HomeOutlineEightIcon } from '@components/icons/HomeOutlineEightIcon'
import { HomeOutlineFiveIcon } from '@components/icons/HomeOutlineFiveIcon'
import { HomeOutlineFourIcon } from '@components/icons/HomeOutlineFourIcon'
import { HomeOutlineNineIcon } from '@components/icons/HomeOutlineNineIcon'
import { HomeOutlineOneIcon } from '@components/icons/HomeOutlineOneIcon'
import { HomeOutlineSevenIcon } from '@components/icons/HomeOutlineSevenIcon'
import { HomeOutlineSixIcon } from '@components/icons/HomeOutlineSixIcon'
import { HomeOutlineTenIcon } from '@components/icons/HomeOutlineTenIcon'
import { HomeOutlineThreeIcon } from '@components/icons/HomeOutlineThreeIcon'
import { HomeOutlineTwoIcon } from '@components/icons/HomeOutlineTwoIcon'
import { OutLineMobileEightIcon } from '@components/icons/OutLineMobileEightIcon'
import { OutLineMobileFiveIcon } from '@components/icons/OutLineMobileFiveIcon'
import { OutLineMobileFourIcon } from '@components/icons/OutLineMobileFourIcon'
import { OutLineMobileNineIcon } from '@components/icons/OutLineMobileNineIcon'
import { OutLineMobileOneIcon } from '@components/icons/OutLineMobileOneIcon'
import { OutLineMobileSevenIcon } from '@components/icons/OutLineMobileSevenIcon'
import { OutLineMobileSixIcon } from '@components/icons/OutLineMobileSixIcon'
import { OutLineMobileTenIcon } from '@components/icons/OutLineMobileTenIcon'
import { OutLineMobileThreeIcon } from '@components/icons/OutLineMobileThreeIcon'
import { OutLineMobileTwoIcon } from '@components/icons/OutLineMobileTwoIcon'

export const rankNumbers = [
  <>
    <HomeOutlineOneIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileOneIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
  <>
    <HomeOutlineTwoIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileTwoIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
  <>
    <HomeOutlineThreeIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileThreeIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
  <>
    <HomeOutlineFourIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileFourIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
  <>
    <HomeOutlineFiveIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileFiveIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
  <>
    <HomeOutlineSixIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileSixIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
  <>
    <HomeOutlineSevenIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileSevenIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
  <>
    <HomeOutlineEightIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileEightIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
  <>
    <HomeOutlineNineIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileNineIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
  <>
    <HomeOutlineTenIcon
      width='100'
      height='152'
      className='text-primary mobile:hidden'
    />
    <OutLineMobileTenIcon
      width='72'
      height='109'
      className='desktop-tablet:hidden text-primary'
    />
  </>,
]
