import { Expose, Type, Transform } from 'class-transformer'

class PenNameType {
  @Expose()
  firstPenName: string
}

class BookType {
  @Expose() id: number

  @Expose() writer: string

  @Type(() => PenNameType)
  penName: PenNameType

  @Expose() coverResizeImgPath: string

  @Expose() coverImgPath: string

  @Expose() title: string

  // TODO: ใช้อะป่าววววว
  @Expose()
  @Transform(({ value }) =>
    value === 'original'
      ? 'ออริจินอล'
      : value === 'fanfiction'
      ? 'แฟนฟิกชัน'
      : ''
  )
  writingType: string

  @Expose() isTranslated: boolean
}

export class BookBannerType {
  @Expose()
  id: number

  @Expose()
  bookId: number

  @Expose({ name: 'runningNo' })
  order: number

  @Expose()
  @Type(() => BookType)
  book: BookType

  @Expose()
  isOnWeb: boolean

  @Expose()
  isEbook: boolean
}
