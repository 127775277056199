import React, { useEffect, useRef } from 'react'
import cn from 'classnames'

import { TooltipProps } from './interface'

export function Tooltip({ children, label, className }: TooltipProps) {
  const ref = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const labelRef = useRef<HTMLDivElement>(null)
  const triangleRef = useRef<HTMLDivElement>(null)

  function handleMouseDown(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation()

    toggleTooltip()
  }

  function handleMouseEnter() {
    // DESC: onMouseEnter can change visible state when on desktop screen
    if (window.innerWidth >= 1280) {
      showTooltip()
    }
  }

  function showTooltip() {
    const { left, top } = containerRef.current!.getBoundingClientRect()
    const { height, width } = ref.current!.getBoundingClientRect()
    const { offsetWidth } = labelRef.current!

    if (left + offsetWidth >= window.innerWidth) {
      labelRef.current!.style.right = '0'
      labelRef.current!.style.left = ''
    } else {
      labelRef.current!.style.left = `${(width / 2) * -1}px`
      labelRef.current!.style.right = ''
    }
    triangleRef.current!.style.left = `${width / 2}px`

    labelRef.current?.classList.remove('invisible')
    triangleRef.current?.classList.remove('invisible')
  }

  function toggleTooltip() {
    if (labelRef.current?.className.includes('invisible')) {
      showTooltip()
    } else {
      hideTooltip()
    }
  }

  function hideTooltip() {
    labelRef.current?.classList.add('invisible')
    triangleRef.current?.classList.add('invisible')
  }

  useEffect(() => {
    labelRef.current!.style.right = '0'

    if (window !== undefined) {
      window.addEventListener('scroll', hideTooltip)
    }

    return () => {
      window.removeEventListener('scroll', hideTooltip)
    }
  }, [])

  return (
    <div ref={containerRef} className='relative'>
      <div
        ref={ref}
        className='w-fit cursor-pointer'
        onClick={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>

      <div
        ref={labelRef}
        className={cn(
          'absolute p-[10px] border border-blue-1 bg-blue-2 rounded-[6px] w-max max-w-[200px] desktop:max-w-[250px] z-10 mt-[10px] invisible',
          className
        )}
      >
        {label}
      </div>
      <div
        ref={triangleRef}
        className='absolute h-[10px] w-[10px] border-l border-t border-blue-1 bg-blue-2 rotate-45 z-10 -translate-x-1/2 mt-[5px] invisible'
      />
    </div>
  )
}
