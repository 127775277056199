import { gql } from 'graphql-request'

export const GET_ALL_ACTIVE_HIGHLIGHT_BANNER = gql`
  query GetAllActiveHighlightBanner {
    highlightBannersTop: getAllActiveHighlightBanner(position: TOP) {
      id
      bannerName
      bannerType
      isActive
      paginatedPosterBanner(limit: 10) {
        id
        runningNo
        imgPath
        description
        linkWeb
        isOnWeb
      }
      paginatedBookBanner(limit: 10) {
        id
        bookId
        runningNo
        isOnWeb
        isEbook
        book {
          id
          title
          writer
          coverImgPath
          coverResizeImgPath
          writingType
          isTranslated
          penName {
            firstPenName
          }
        }
      }
    }
    highlightBannersBottom: getAllActiveHighlightBanner(position: BOTTOM) {
      id
      bannerName
      bannerType
      isActive
      paginatedPosterBanner(limit: 10) {
        id
        runningNo
        imgPath
        description
        linkWeb
        isOnWeb
      }
      paginatedBookBanner(limit: 10) {
        id
        bookId
        runningNo
        isOnWeb
        isEbook
        book {
          id
          title
          writer
          coverImgPath
          coverResizeImgPath
          writingType
          isTranslated
          penName {
            firstPenName
          }
        }
      }
    }
  }
`
