import { MainBannerSkeleton } from '@components/BannerSkeleton'
import { useBannerAction } from '@hooks/banner/useBannerAction'
import { useQuery } from 'react-query'
import { BannerSectionProps } from './interface'

export function withBannerSection(Component: React.FC<BannerSectionProps>) {
  function WithBannerSection() {
    const bannerClient = useBannerAction()
    const { data, isLoading } = useQuery('main-banner', () =>
      bannerClient.getMainBanners()
    )

    if (isLoading) return <MainBannerSkeleton />

    return <Component mainBanners={data} />
  }
  return WithBannerSection
}
