import { HighlightBannerSkeleton } from '@components/BannerSkeleton'
import { WithHighlightBannerProps, HighlightBannerProps } from './interface'

export function withHighlightBanner(Component: React.FC<HighlightBannerProps>) {
  function WithHighlightBanner({
    highlightBanners,
    isLoading,
    className,
  }: WithHighlightBannerProps) {
    if (isLoading) return <HighlightBannerSkeleton />

    if (!highlightBanners || !highlightBanners?.length) return <></>

    const componentProps = {
      className,
      highlightBanners,
    }

    return <Component {...componentProps} />
  }

  return WithHighlightBanner
}
