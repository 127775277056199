import { useState } from 'react'
import { useDrawer } from '@hooks/contexts/DrawerContext/DrawerContext'
import { useMenu } from '@hooks/home/useMenu'
import { CategoryMenuProps, WithCategoryMenuProps } from './interface'

export function withCategoryMenu(Component: React.FC<CategoryMenuProps>) {
  function WithCategoryMenu(props: WithCategoryMenuProps) {
    const [selectedCategory, setSelectedCategory] = useState('')
    const { data: menus } = useMenu()
    const categoryDrawer = useDrawer({
      drawer: 'categoryMenu',
    })

    function handleCategoryMenuChange(value: string) {
      setSelectedCategory(value)
      categoryDrawer.show({
        value,
        onChange: (newValue: string) => {
          setSelectedCategory(newValue)
        },
      })
    }

    const menuOptions = menus.map(menu => ({
      text: menu.name,
      value: menu.menuType,
      icon: menu.icon,
    }))

    const componentProps = {
      ...props,
      selectedCategory,
      menuOptions,
      handleCategoryMenuChange,
    }
    return <Component {...componentProps} />
  }

  return WithCategoryMenu
}
