import { gql } from 'graphql-request'

export const LASTEST_UPDATED_BOOKS_HOME = gql`
  query LatestUpdatedBooksHome {
    latestUpdatedBooksHome {
      id
      writer
      coverImgPath
      coverResizeImgPath
      cover
      title
      publishedChapterCount
      viewCount
      isLiked
      likeCount
      commentCount
      isEnded
      category {
        id
        name
      }
      penName {
        firstPenName
        user {
          id
          displayName
          profilePageSlug
          avatarImgPath
          writer {
            affiliation
            status
          }
        }
      }
      tags {
        id
        name
      }
      bookType
      publishedAt
      writingType
      isTranslated
      isEpub
      isPdf
      rating {
        id
        name
      }
    }
  }
`
